<template>
  <b-form-group label="Linkek">
    <ul class="list-group dense">
      <draggable :list="form.Linkek" :options="dragOptions">
        <li
          v-for="link in visibleLinkek"
          :key="link.Id"
          class="list-group-item hidden-container"
        >
          <div
            class="d-flex justify-content-between align-items-center flex-fill"
          >
            <div class="w-100 pr-4">
              <k-inline-text-edit :value.sync="link.Nev" :text="link.Nev">
              </k-inline-text-edit>

              <k-cikk-valaszto
                v-if="link.isBelso || link.Tipus.Id == LinkTipusok.Belso"
                v-model="link.Cikk"
              >
              </k-cikk-valaszto>
              <k-inline-text-edit
                :value.sync="link.Url"
                :text="link.Url"
                v-else
              >
              </k-inline-text-edit>
            </div>
            <div
              class="li-tile mr-2 pointer hidden-item"
              @click="RemoveLink(link)"
              v-b-tooltip="'Törlés'"
            >
              <i class="fa fa-trash"></i>
            </div>
          </div>
        </li>
      </draggable>
      <li
        class="list-group-item d-flex justify-content-start align-items-center pointer"
        @click="UjBelsoLink"
      >
        <div class="li-tile grey mr-2">
          <i class="fa fa-plus"></i>
        </div>
        <span>
          Új belső link
        </span>
      </li>
      <li
        class="list-group-item d-flex justify-content-start align-items-center pointer"
        @click="UjKulsoLink"
      >
        <div class="li-tile grey mr-2">
          <i class="fa fa-plus"></i>
        </div>
        <span>
          Új külső link
        </span>
      </li>
    </ul>
  </b-form-group>
</template>

<script>
  /* eslint vue/no-mutating-props: 0 */
import { LinkTipusok } from '../../../enums/LinkTipusok';
export default {
  name: 'pcf-linkek',
  data() {
    return {
      ct: -1,
      LinkTipusok,
    };
  },
  mounted() {},
  created() {},
  methods: {
    UjKulsoLink() {
      let id = this.ct--;
      this.form.Linkek.push({
        Id: id,
        Nev: `Link${id}`,
        Url: 'https://example.com',
        ToroltFl: false,
        Cikk: null,
        isBelso: false,
        Tipus: {
          Id: 295,
          Text: 'Külső link',
        },
      });
    },
    UjBelsoLink() {
      let id = this.ct--;
      this.form.Linkek.push({
        Id: id,
        Nev: `Link${id}`,
        Url: null,
        ToroltFl: false,
        Cikk: null,
        isBelso: true,
        Tipus: {
          Id: 294,
          Text: 'Belső link',
        },
      });
    },
    RemoveLink(link) {
      if (link.Id > 0) {
        let prev = this.form.Linkek.find(f => f.Id == link.Id);
        this.$set(prev, 'ToroltFl', true);
      } else {
        this.form.Linkek = this.form.Linkek.filter(f => f.Id != link.Id);
      }
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        scrollSensitivity: 100,
        ignore: 'img',
      };
    },
    visibleLinkek() {
      return this.form.Linkek.filter(f => !f.ToroltFl);
    },
  },
  watch: {},
  props: {
    form: {
      requied: true,
    },
  },
};
</script>
